import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ofType } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';
import axios from "utils/axios";

interface AssessmentsState {
  data: any,
  error: any,
  loading: boolean,
  assignments: Array<any>,
  quizzes: Array<any>,
  mid: Array<any>,
  subjectId: number,
  classroomId: number,
  length: number
}

const initialState: AssessmentsState = {
  data: null,
  error: null,
  loading: false,
  assignments: [],
  mid: [],
  quizzes: [],
  subjectId: null,
  classroomId: null,
  length: null
};


const assessmentsSlice = createSlice({
  name: 'assessments',
  initialState: initialState,
  reducers: {
    fetchAssessments(state, action) {
      state.loading = true;
      state.subjectId = action.payload.subjectId
      state.classroomId = action.payload.classroomId
    },
    fetchedAssessments(state, action) {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data.rules.map(rule => ({
        title: rule.assessmentType.name,
        rule: rule,
        assessments: Object.keys(action.payload.data.assessments)
          .filter(item => item === rule.assessmentType.name).map(item => action.payload.data.assessments[item])[0]
      }))

      state.length = Object.keys(action.payload.data.assessments).map(item => ({
        title: item,
        assessments: action.payload.data.assessments[item],
      })).length

    },
  }
})


export function fetchAssessmentsEpic(action$) {
  return action$.pipe(
    ofType(fetchAssessments.type),
    switchMap(async (action: any) => {
      const res = await axios.get(`/assessments?subjectId=${action.payload.subjectId}&classroomId=${action.payload.classroomId}`);
      return {
        data: res.data,
      }
    }),
    map(fetchedAssessments),
  );
}

const { fetchedAssessments } = assessmentsSlice.actions
export const {
  fetchAssessments,
} = assessmentsSlice.actions;

export default assessmentsSlice.reducer;
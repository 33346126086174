import { Col, Row, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import ClassTimetable from "pages/PrintableClassTimetable";
import React, { useState } from "react";
import styled from "styled-components";
import Spinner from "../../components/Spinner";
import useFetch from "../../hooks/useFetch";

export const StyledTableContainer = styled.div`
  background: white;
  border-radius: 16px;
  table thead tr {
    background-color: #efefef;
  }
  table,
  td,
  th {
    border: 1px solid #bebebe;
    padding: 8px;
    text-align: center;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
`;

export default function PrintBranchWiseTimeTable() {
  const urlParams = new URLSearchParams(window.location.search);
  const courses = urlParams.get("courses");
  const batch = urlParams.get("batches");
  const departmentId = urlParams.get("departmentId");
  const { loading, data } = useFetch(
    `/timetable/branchwise?courses=${courses}&batches=${batch}&departmentId=${departmentId}`
  );

  if (loading) {
    return <Spinner />;
  }

  const faculties = Object.keys(data.list).map((newItem) => ({
    facultyName: data.list[newItem][0].full_name,
    branchCode: data.list[newItem][0].branch_code,
    batch: data.list[newItem][0].barch,
    timings: (() => {
      let items = data.list[newItem].map((item) => ({
        fromTime: item.from_time,
        toTime: item.to_time,
        day: item.day,
        subjectName: item.subject_name,
        subjectCode: item.subject_code,
        facultyName: item.full_name,
        batch: item.batch,
        classroom: `${item.course_name}, ${item.branch_code} - ${item.section}`,
      }));
      return _.groupBy(items, "day");
    })(),
  }));

  console.log(faculties);
  return (
    <div
      style={{
        // width: "25cm",
        // height: "33cm",
        borderRadius: "5px",
        margin: "auto",
      }}
    >
      {faculties.map((faculty) => (
        <div style={{ padding: "12px 24px" }}>
          {/* {item.faculties.map((faculty) => ( */}
          <div style={{ pageBreakAfter: "always", paddingTop: 12 }}>
            <header>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottom: "1px solid black",
                  paddingBottom: "4px",
                  marginBottom: "4px",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <img src={data.school?.logo} style={{ height: "2rem" }} />
                  <div
                    className="address"
                    style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                  >
                    {data.school?.address}
                  </div>
                </div>
              </div>
            </header>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: 0,
              }}
            >
              <div style={{ fontWeight: "bold" }}>
                Faculty Name: {faculty.facultyName}
              </div>

              {/* <div style={{ fontWeight: "bold" }}>Batch: {faculty.batch}</div> */}
              <Typography.Title style={{ textAlign: "center" }} level={5}>
                Department: {faculty.branchCode}
              </Typography.Title>
            </div>
            <ClassTimetable timetableByDays={faculty.timings} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: 36,
              }}
            >
              <div>Signature of the faculty</div>
              <div>Signature of the HOD</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Typography } from "antd";
import Item from "antd/lib/list/Item";
import moment from "moment";
import React from "react";
import styled from "styled-components";

const { Text } = Typography;
const timetableHeight = "500px";
const startTime = "09:00:00";
const endTime = "17:00:00";
const interval = 60;
const borderColor = "#000";

const StyledTimetable = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-right: 1px solid ${borderColor};

  .events-container {
    display: flex;
    flex-direction: column;
    height: ${timetableHeight};
  }
`;

const StyledPeriodComponent = styled.div<{ height: number }>`
  background: white;
  position: relative;
  text-align: center;
  display: grid;
  justify-content: center;
  align-items: center;
  color: black;
  // padding: 8px;
  height: ${(props) => `${props.height}%`};
  border: 1px solid ${borderColor};
  /* border-radius: 8px; */
`;

const StyledDayHeading = styled.div`
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid ${borderColor};
  font-weight: bold;
`;

const StyledTimeHeading = styled.div`
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid ${borderColor};
`;

const StyledDayTimetable = styled.div`
  flex: 1;
  border: 1px solid ${borderColor};
  border-right: none;
`;

const StyledTimeColumn = styled.div`
  border: 1px solid ${borderColor};
  border-right: none;
`;

const StyledTimeComponent = styled.div<{ height: number }>`
  padding: 8px;
  height: ${(props) => `${props.height}%`};
  border-bottom: 1px solid ${borderColor};
`;

interface ClassTimetableProps {
  timetableByDays: {
    [day: string]: Timings[];
  };
}

interface Timings {
  fromTime: string;
  toTime: string;
  day: string;
  subjectName: string;
  facultyName: string;
}

export default function PrintableClassTimetable({
  timetableByDays,
}: ClassTimetableProps) {
  return (
    <div className="timetable-container">
      <StyledTimetable>
        <TimeColumn day="Monday" />
        {[
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ].map((item) => (
          <DayTimetable
            key={item}
            periods={timetableByDays[item.toUpperCase()]}
            day={item}
          />
        ))}
      </StyledTimetable>
    </div>
  );
}

function DayTimetable({ day, periods }: { day: string; periods: Timings[] }) {
  return (
    <StyledDayTimetable>
      <StyledDayHeading>{day}</StyledDayHeading>

      <div className="events-container">
        <Periods periods={periods} />
      </div>
    </StyledDayTimetable>
  );
}

interface PeriodsProps {
  periods: Array<Timings>;
}

function Periods({ periods }: PeriodsProps) {
  let lastTime = startTime;

  if (!periods) {
    return null;
  }

  return (
    <>
      {periods.map((item, index) => {
        let fromTime = moment(item.fromTime, "HH:mm:ss");
        let diffMinutes = fromTime.diff(
          moment(lastTime, "HH:mm:ss"),
          "minutes"
        );

        lastTime = item.toTime;

        return (
          <>
            {diffMinutes > 0 && <Gap key={index} minutes={diffMinutes} />}
            <PeriodComponent key={index} period={item} />
          </>
        );
      })}

      {/* {renderFinalGap(lastTime)} */}
    </>
  );
}

function renderFinalGap(lastTime) {
  let diffMinutes = moment(endTime, "HH:mm:ss").diff(
    moment(lastTime, "HH:mm:ss"),
    "minutes"
  );

  if (diffMinutes > 0) {
    return <Gap minutes={diffMinutes} />;
  }

  return null;
}

function PeriodComponent({ period }) {
  let minutes = moment(period.toTime, "HH:mm:ss").diff(
    moment(period.fromTime, "HH:mm:ss"),
    "minutes"
  );
  let height = (minutes / 480) * 100;

  return (
    <StyledPeriodComponent height={height}>
      <Row gutter={16}>
        <Col xs={24}>
          <div style={{ fontSize: 10, lineHeight: 1 }}>
            {period.subjectName ?? period.content}
          </div>

          <div style={{ fontSize: 8 }}>
            {period.batch ?? ""} - {period.classroom ?? ""}
          </div>
          <div style={{ fontSize: 8 }}>
            {moment(period.fromTime, "HH:mm:ss").format("hh:mm A") ?? ""} -{" "}
            {moment(period.toTime, "HH:mm:ss").format("hh:mm A") ?? ""}
          </div>
        </Col>
      </Row>
    </StyledPeriodComponent>
  );
}

function Gap({ minutes }) {
  let height = (minutes / 480) * 100;

  return (
    <>
      <div
        style={{
          height: `${height}% `,
        }}
      ></div>
    </>
  );
}

function TimeColumn({ day }) {
  return (
    <StyledTimeColumn
      style={{
        flex: 0.5,
      }}
    >
      <StyledTimeHeading>Time</StyledTimeHeading>

      <div className="events-container">
        <Timings />
      </div>
    </StyledTimeColumn>
  );
}

function Timings() {
  let lastTime = startTime;
  let timings = getTimeStringsInRange(startTime, endTime, interval);

  return (
    <>
      {timings.map((item, index) => {
        lastTime = item.toTime;

        return (
          <>
            <TimeComponent key={index} displayText={item} />
          </>
        );
      })}
    </>
  );
}

function TimeComponent({ displayText }) {
  let height = (interval / 480) * 100;

  return (
    <StyledTimeComponent height={height}>{displayText}</StyledTimeComponent>
  );
}

function getTimeStringsInRange(startTimeString, endTimeString, interval) {
  let endTime = moment(endTimeString, "HH:mm:ss");
  let pivot = moment(startTimeString, "HH:mm:ss");

  let timings = [];

  while (endTime.diff(pivot, "minutes") > 0) {
    timings.push(pivot.format("hh:mm A"));
    pivot = pivot.add(interval, "minutes");
  }

  return timings;
}

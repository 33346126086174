import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ofType} from 'redux-observable';
import {switchMap, map} from 'rxjs/operators';
import axios from "utils/axios";
import {fetchBatchSubjects} from "./batchSubjectsSlice";

function print(proxy) {
  console.log((JSON.stringify(proxy)));
}

interface sessionsState {
  data: any,
  error: any,
  loading: boolean,
  sessions: any,
  selectedSessionIndex: number,
  saved: boolean;
}

const initialState: sessionsState = {
  data: {},
  error: null,
  loading: false,
  selectedSessionIndex: null,
  sessions: [],
  saved: true
};

const sessionsSlice = createSlice({
  name: 'sessions',
  initialState: initialState,
  reducers: {
    fetchSessions(state, action) {
      state.loading = true;
    },
    saved(state) {
      state.saved = true;
    },
    fetchedSessions(state, action) {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
      state.saved = true;
      state.sessions = action.payload.data.lessonPlanTemplates.map(item => {
        return {
          ...item,
          sessionSubTitle: getSessionSubTitle(item)
        }
      })
    },
    updateSessionOrder(state, action) {
      state.sessions = [...action.payload.list]
      state.sessions = state.sessions.map(item => {
        return {
          ...item,
          sessionSubTitle: getSessionSubTitle(item)
        }
      })
    },
    selectSession(state, action) {
      state.loading = false;
      state.error = null;
      // state.selectedSession = action.payload.selectedSession;
      state.selectedSessionIndex = action.payload.index
    },
    createSession(state, action) {
      state.loading = false
    },
    createdSession(state, action) {
      state.loading = false
    },
    addBelow(state, action) {
      state.loading = true
    },
    updateValue(state, action) {
      state.loading = false
      let {key, value} = action.payload;
      let session = state.sessions[state.selectedSessionIndex];
      session[key] = value;
      session.sessionSubTitle = getSessionSubTitle(session);
      state.saved = false;
    }
  }
})

function getSessionSubTitle(session) {
  let title = 'NA'
  let units = '', topics = '';
  
  if (session.units && session.units.length) {
    units = session.units.join(', ')
  }
  
  if (session.topics && session.topics.length) {
    topics = session.topics.join(', ')
  }
  
  if (units != '') {
    title = 'Units: ' + units;
  }
  
  if (topics != '') {
    title = title + ', Topics: ' + topics;
  }
  
  return title;
}

export function fetchSessionsEpic(action$) {
  return action$.pipe(
    ofType(fetchSessions.type),
    switchMap(async (action: any) => {
      const res = await axios.get(`/lesson-plan-template?subjectId=${action.payload.curriculumSubjectId}`);
      return {
        data: res.data,
      }
    }),
    map(fetchedSessions),
  );
}


export function createSessionsEpic(action$) {
  return action$.pipe(
    ofType(createSession.type),
    switchMap(async (action: any) => {
      let data = {
        curriculumSubjectId: action.payload.curriculumSubjectId,
        content: " ",
      }
      const res = await axios.post(`/lesson-plan-template`, data);
      return {
        curriculumSubjectId: res.data.curriculumSubjectId,
      }
    }),
    map(fetchSessions),
  );
}

export function addBelowSessionsEpic(action$) {
  return action$.pipe(
    ofType(addBelow.type),
    switchMap(async (action: any) => {
      let data = {
        curriculumSubjectId: action.payload.curriculumSubjectId,
        session: action.payload.session,
      }
      const res = await axios.post(`/lesson-plan-template/add-below`, data);
      return {
        curriculumSubjectId: res.data.curriculumSubjectId,
      }
    }),
    map(fetchSessions),
  );
}

export const {
  fetchSessions, fetchedSessions,
  createSession,
  selectSession,
  createdSession,
  updateValue,
  addBelow,
  updateSessionOrder,
  saved
} = sessionsSlice.actions;

export default sessionsSlice.reducer;



import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ofType} from 'redux-observable';
import {switchMap, map} from 'rxjs/operators';
import axios from "utils/axios";


interface LessonPlanState {
  date: any,
  data: any,
  selectedSessionIndex: any,
  selectedSession: any,
  sessions: any,
  error: any,
  loading: boolean,
  serverError: any,
  saved: boolean;
}

const initialState: LessonPlanState = {
  date: null,
  data: null,
  selectedSession: null,
  selectedSessionIndex: null,
  sessions: [],
  error: null,
  loading: false,
  serverError: null,
  saved: true,
};

const lessonPlanSlice = createSlice({
  name: 'lessonPlan',
  initialState: initialState,
  reducers: {
    fetchLessonPlan(state, action) {
      state.loading = false;
    },
    fetchedLessonPlan(state, action) {
      state.loading = false;
      if (action.payload.error) {
        state.serverError = action.payload.error;
        return;
      }
      
      state.error = null;
      state.data = action.payload.data;
      state.sessions = action.payload.data.map(session => ({
        ...session,
        sessionSubTitle: getSessionSubTitle(session)
      }));
    },
    updateSessionOrder(state, action) {
      state.sessions = [...action.payload.list]
      state.sessions = state.sessions.map(item => {
        return {
          ...item,
          sessionSubTitle: getSessionSubTitle(item)
        }
      })
    },
    createSession(state, action) {
      state.loading = false
    },
    createdSession(state, action) {
      state.loading = false
    },
    selectSession(state, action) {
      state.loading = false;
      state.error = null;
      state.selectedSession = action.payload.session
      state.selectedSessionIndex = action.payload.index
    },
    updateValue(state, action) {
      state.loading = false
      let {key, value} = action.payload;
      state.sessions[state.selectedSessionIndex][key] = value;
      state.saved = false;
    },
    addBelow(state, action) {
      state.loading = false
    },
    clearState(state, action) {
      state.sessions = [];
      state.selectedSessionIndex = null;
      state.selectedSession = null;
    }
  }
})

function getSessionSubTitle(session) {
  let title = 'NA'
  let units = '', topics = '';
  
  if (session.units && session.units.length) {
    units = session.units.join(', ')
  }
  
  if (session.topics && session.topics.length) {
    topics = session.topics.join(', ')
  }
  
  if (units != '') {
    title = 'Units: ' + units;
  }
  
  if (topics != '') {
    title = title + ', Topics: ' + topics;
  }
  
  return title;
}

export function fetchLessonPlanEpic(action$) {
  return action$.pipe(
    ofType(fetchLessonPlan.type),
    switchMap(async (action: any) => {
      try {
        const res = await axios.get(`/lesson-plan?classroomSubjectId=${action.payload.classroomSubjectId}`);
        
        return {
          data: res.data,
        }
      } catch (err) {
        if (err.response?.status == 422) {
          return {
            data: null,
            error: err.response.data.message
          }
        }
        return {
          data: null,
          error: err.message
        }
      }
    }),
    map(fetchedLessonPlan),
  );
}

export function createClassroomSessionEpic(action$) {
  return action$.pipe(
    ofType(createSession.type),
    switchMap(async (action: any) => {
      let data = {
        classroomSubjectId: action.payload.classroomSubjectId,
        content: " ",
      }
      const res = await axios.post(`/lesson-plan`, data);
      return {
        classroomSubjectId: res.data.data.classroomSubject.id,
      }
    }),
    map(fetchLessonPlan),
  );
}

export function addBelowClassroomSessionsEpic(action$) {
  return action$.pipe(
    ofType(addBelow.type),
    switchMap(async (action: any) => {
      let data = {
        classroomSubjectId: action.payload.classroomSubjectId,
        session: action.payload.session,
      }
      const res = await axios.post(`/lesson-plan/add-below`, data);
      return {
        classroomSubjectId: res.data.classroomSubject.id,
      }
    }),
    map(fetchLessonPlan),
  );
}

export const {
  fetchLessonPlan, fetchedLessonPlan, selectSession,
  updateValue, clearState, createSession, createdSession,
  updateSessionOrder,addBelow
} = lessonPlanSlice.actions;

export default lessonPlanSlice.reducer;



import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ofType } from "redux-observable";
import { switchMap, map } from "rxjs/operators";
import axios from "utils/axios";
import _ from "lodash";
import moment from "moment";
import { selectClassroom } from "./classroomSlice";

interface TimetableState {
  data: any;
  error: any;
  loading: boolean;
  timetableByDays: any;
  lastTime: string;
}

const initialState: TimetableState = {
  data: null,
  error: null,
  loading: false,
  timetableByDays: {},
  lastTime: "09:00:00",
};

const timetableSlice = createSlice({
  name: "timetable",
  initialState: initialState,
  reducers: {
    fetchTimetable(state, action) {
      state.loading = true;
    },
    fetchedTimetable(state, action) {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
      state.timetableByDays = action.payload.timetableByDays;
    },
  },
});

export function fetchClassroomTimetableEpic(action$) {
  return action$.pipe(
    ofType(selectClassroom.type),
    switchMap(async (action: any) => {
      return {
        classroomId: action.payload.selectedClassroomId,
      };
    }),
    map(fetchTimetable)
  );
}

export function fetchTimetableEpic(action$) {
  return action$.pipe(
    ofType(fetchTimetable.type),
    switchMap(async (action: any) => {
      const res = await axios.get(
        `/timetable/classroom?classroomId=${action.payload.classroomId}`
      );

      let timetableByDays = {};

      const formattedData = res.data.map((item) => ({
        fromTime: item.from_time,
        toTime: item.to_time,
        day: item.day,
        subjectName: item.subject_name,
        subjectCode: item.subject_code,
        facultyName: item.full_name,
      }));
      timetableByDays = _.groupBy(formattedData, "day");

      return {
        data: res.data,
        timetableByDays: timetableByDays,
      };
    }),
    map(fetchedTimetable)
  );
}

export const { fetchedTimetable, fetchTimetable } = timetableSlice.actions;

export default timetableSlice.reducer;

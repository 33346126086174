import loadable from "@loadable/component";
import PrintBranchWiseTimeTable from "pages/Timetable/PrintBranchWiseTimeTable";

const MyClassrooms = loadable(() => import("./pages/MyClassrooms"));
const LessonPlan = loadable(() => import("pages/LessonPlanTemplate/Subjects"));
const AwardMarks = loadable(() => import("pages/Assesments/AwardMarks"));
const SubjectSessions = loadable(
  () => import("pages/LessonPlanTemplate/SubjectSessions")
);
const Faculty = loadable(() => import("pages/Faculty"));
const DetailedPerformance = loadable(() => import("pages/DetailedPerformance"));
const TheLayout = loadable(() => import("components/TheLayout"));
const Dashboard = loadable(() => import("pages/Dashboard"));
const ClassRooms = loadable(() => import("pages/ClassRooms"));
const Branch = loadable(() => import("pages/Branch"));
const FacultyBranch = loadable(() => import("pages/FacultyBranch"));
const Subject = loadable(() => import("pages/Subject"));
const StudentProfile = loadable(() => import("pages/StudentProfile"));
const FacultyProfile = loadable(() => import("pages/FacultyProfile"));
const Profile = loadable(() => import("pages/Profile"));
const AssessmentReport = loadable(() => import("pages/AssessmentReport"));
const AssessmentStatusReport = loadable(
  () => import("pages/AssessmentReport/AssessmentStatusReport")
);
const Timetable = loadable(() => import("pages/Timetable"));

export interface IRoute {
  component: any;
  exact?: boolean;
  name: string;
  path: string;
  routes?: Array<IRoute>;
}

const routes: Array<IRoute> = [
  {
    component: AssessmentReport,
    exact: true,
    path: "/assessment-report",
    name: "Assessment Report",
  },
  {
    component: AssessmentStatusReport,
    exact: true,
    path: "/assessment-status-report",
    name: "Assessment Status Report",
  },
  {
    component: PrintBranchWiseTimeTable,
    exact: true,
    path: "/branch-wise-timetable-report",
    name: "Branch wise Timetable Report",
  },
  {
    component: TheLayout,
    name: "Dashboard",
    path: "/",
    exact: false,
    routes: [
      {
        component: Dashboard,
        exact: true,
        name: "Dashboard",
        path: "/dashboard",
      },
      {
        component: Subject,
        exact: true,
        name: "Subject",
        path: "/class-rooms/:batch/:programId/:classroomId/subject/:subjectId",
      },
      {
        component: Branch,
        exact: true,
        name: "Branch",
        path: "/class-rooms/:batch/:programId/:classroomId",
      },
      {
        component: ClassRooms,
        exact: true,
        name: "ClassRooms",
        path: "/class-rooms",
      },
      {
        component: Subject,
        exact: true,
        name: "Subject",
        path: "/my-class-rooms/:batch/:classroomId/subject/:subjectId",
      },
      {
        component: FacultyBranch,
        exact: true,
        name: "Branch",
        path: "/my-class-rooms/:batch/:classroomId",
      },
      {
        component: MyClassrooms,
        exact: true,
        name: "My Classrooms",
        path: "/my-class-rooms",
      },
      {
        component: SubjectSessions,
        exact: true,
        name: "Subjects",
        path: "/lesson-plan/:batchId/subject/:subjectId/sessions",
      },
      {
        component: Profile,
        exact: true,
        name: "Profile",
        path: "/profile",
      },
      {
        component: FacultyProfile,
        exact: true,
        name: "Faculty Profile",
        path: "/faculty-profile",
      },
      {
        component: Faculty,
        exact: true,
        name: "Faculty",
        path: "/faculty",
      },
      {
        component: LessonPlan,
        exact: true,
        name: "Lesson Plan",
        path: "/lesson-plan",
      },
      {
        component: StudentProfile,
        exact: true,
        name: "Student Profile",
        path: "/student-profile/:studentId",
      },
      {
        component: DetailedPerformance,
        exact: true,
        name: "Student Performance",
        path: "/detailed-performance/:studentId",
      },
      {
        component: AwardMarks,
        exact: true,
        name: "AwardMarks",
        path: "/assessment/award-marks",
      },
      {
        component: Timetable,
        exact: true,
        name: "Timetable",
        path: "/timetable",
      },
    ],
  },
];

export default routes;

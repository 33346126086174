import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  Card,
  Space,
  Alert,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import axios from "axios";
import "./LoginPage.css";
import logoWhite from "assets/images/logo-white.png";
import ErrorBox from "components/ErrorBox";
interface ICredentials {
  username: string;
  password: string;
}

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<any>({
    loading: false,
    error: null,
  });
  useEffect(() => {
    if (localStorage.getItem("token")) {
      window.location.replace("/");
    }
  });

  const onFinish = (values: ICredentials) => {
    setState({
      loading: true,
      error: null,
    });
    axios
      .post(`${process.env.REACT_APP_API_HOST}/auth/hod-login`, values)
      .then((res) => {
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("schoolId", "1");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setState({
          loading: false,
          error: err,
        });
      });
  };

  return (
    <div id="LoginPage">
      <Row className="main-row">
        <Col lg={12} xs={0} className="left-content">
          <Row
            justify="center"
            align="middle"
            className="background-with-image"
            style={{ height: "inherit" }}
          ></Row>
        </Col>

        <Col lg={12} sm={24} xs={24} className="right-content">
          <Row justify="center" align="middle" style={{ height: "inherit" }}>
            <Col lg={12} xs={20}>
              <Space direction="vertical" style={{ width: "100%" }} size={32}>
                <div style={{ textAlign: "center" }}>
                  <img
                    src={process.env.REACT_APP_LOGO}
                    style={{
                      width: "200px",
                    }}
                  />
                </div>

                <Typography.Title level={4} style={{ textAlign: "center" }}>
                  Sign in to your account
                </Typography.Title>
                <div>
                  <ErrorBox err={state.error} />
                  {renderLoginForm()}
                </div>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );

  function renderLoginForm() {
    return (
      <div>
        <Form
          initialValues={{
            username: "",
            password: "",
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your Username!" }]}
          >
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              block
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
};

export default LoginPage;

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ofType} from 'redux-observable';
import {switchMap, map} from 'rxjs/operators';
import axios from "utils/axios";
import {Modal} from "antd";
import serverErrorDialog from "../../components/serverErrorDialog";

interface AssessmentMarksState {
  data: any,
  error: any,
  loading: boolean,
  assessment: any,
  classroomSubject: any,
  studentAssessments: Array<any>
}

const initialState: AssessmentMarksState = {
  data: null,
  error: null,
  loading: false,
  assessment: null,
  classroomSubject: null,
  studentAssessments: []
  
};


const assessmentMarksSlice = createSlice({
  name: 'assessmentMarks',
  initialState: initialState,
  reducers: {
    fetchAssessmentMarks(state, action) {
      state.loading = true;
    },
    fetchedAssessmentMarks(state, action) {
      if (!action.payload) {
        state.loading = false;
        return;
      }

      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
      state.assessment = action.payload.data.assessment;
      state.classroomSubject = action.payload.data.classroomSubject;
      state.studentAssessments = action.payload.data.studentAssessments;
    },
    updateMarks(state, action) {
      state.studentAssessments[action.payload.index].marks = action.payload.marks
    },
    isAbsent(state, action) {
      state.studentAssessments[action.payload.index].isAbsent = action.payload.isAbsent
      if(action.payload.isAbsent == true) {
        state.studentAssessments[action.payload.index].marks = 0
      }
    },
   
  }
})


export function fetchAssessmentMarksEpic(action$) {
  return action$.pipe(
    ofType(fetchAssessmentMarks.type),
    switchMap(async (action: any) => {
      try {
        const res = await axios.get(`/student-assessments?internalAssessmentId=${action.payload.internalAssessmentId}`);
        return {
          data: res.data,
          assessment: res.data.assessment,
          classroomSubject: res.data.classroomSubject,
          studentAssessments: res.data.studentAssessments,
        }
      } catch (err) {
        if (err.response.status == 422) {
          Modal.confirm({
            title: 'Error',
            content: err.response.data.message,
            okText: 'Create course registrations',
            cancelText: 'Cancel',
            onOk: () => {
              axios.get(`/student-assessments/sync-with-course-registrations?internalAssessmentId=${action.payload.internalAssessmentId}`)
                .then(res => {
                  alert('registered successfully')
                  window.location.reload()
                }).catch(err => {
                  alert('error')
                })
            }
          })
        }
      }
    }),
    map(fetchedAssessmentMarks),
  );
}


const {fetchedAssessmentMarks} = assessmentMarksSlice.actions
export const {
  fetchAssessmentMarks, updateMarks,isAbsent
} = assessmentMarksSlice.actions

export default assessmentMarksSlice.reducer;
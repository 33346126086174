import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ofType } from "redux-observable";
import { switchMap, map } from "rxjs/operators";
import axios from "utils/axios";
import _ from "lodash";
import moment from "moment";

interface BatchState {
  data: Array<object>;
  batches: Array<object>;
  classrooms: Array<object>;
  students: Array<object>;
  subjects: Array<object>;
  selectedBatch: any;
  selectedClassroomId: any;
  error: any;
  loading: boolean;
  batch: object;
  branchCode: any;
  courseId: null;
}

const initialState: BatchState = {
  data: [],
  batches: [],
  classrooms: [],
  students: [],
  subjects: [],
  selectedBatch: null,
  selectedClassroomId: null,
  error: null,
  loading: false,
  batch: null,
  branchCode: null,
  courseId: null,
};

const classroomSlice = createSlice({
  name: "classroom",
  initialState: initialState,
  reducers: {
    fetchClassrooms(state, action) {
      state.loading = true;
      state.selectedBatch = action.payload.batch;
      state.branchCode = action.payload.branchCode;
      state.courseId = action.payload.courseId;
    },
    fetchedClassrooms(state, action) {
      state.loading = false;
      state.error = null;
      state.classrooms = action.payload.data;
    },
    selectClassroom(state, action) {
      state.selectedClassroomId = action.payload.selectedClassroomId;
    },
    setClassroomData(state, action) {
      state.loading = false;
      state.error = action.payload?.err;
      state.subjects = action.payload.subjects;
      state.students = action.payload.students;
    },
  },
});

export function fetchClassroomsEpic(action$) {
  return action$.pipe(
    ofType(fetchClassrooms.type),
    switchMap(async (action: any) => {
      console.log(action.payload);
      const res = await axios.get(`/classrooms`, {
        params: {
          batch: action.payload.batch,
          branchCode: action.payload.branchCode,
          courseId: action.payload.courseId,
        },
      });
      return {
        data: res.data,
      };
    }),
    map(fetchedClassrooms)
  );
}

export function fetchClassroomDataEpic(action$) {
  return action$.pipe(
    ofType(selectClassroom.type),
    switchMap(async (action: any) => {
      try {
        console.log(action.payload);
        const subjectRes = await axios.get(
          `/classrooms/${action.payload.selectedClassroomId}/subjects`
        );
        const studentsRes = await axios.get(
          `/students?classroomId=${action.payload.selectedClassroomId}`
        );
        return {
          subjects: subjectRes.data,
          students: studentsRes.data,
        };
      } catch (err) {
        return {
          err,
        };
      }
    }),
    map(setClassroomData)
  );
}

const { fetchedClassrooms, setClassroomData } = classroomSlice.actions;

export const { fetchClassrooms, selectClassroom } = classroomSlice.actions;

export default classroomSlice.reducer;

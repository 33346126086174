import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ofType} from 'redux-observable';
import {switchMap, map} from 'rxjs/operators';
import axios from "utils/axios";

interface UserPermissionsState {
  data: any,
  permissions:any,
  roles:any,
  user:any,
  isHOD:boolean,
  error: any,
  loading: boolean,
}

const initialState: UserPermissionsState = {
  data: null,
  permissions:null,
  roles:null,
  isHOD:false,
  user:null,
  error: null,
  loading: false,
};


const userPermissionsSlice = createSlice({
  name: 'userPermissions',
  initialState: initialState,
  reducers: {
    fetchUserPermissions(state, action) {
      state.loading = true;
    },
    fetchedUserPermissions(state, action) {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
      state.permissions = action.payload.data.permissions;
      state.roles = action.payload.data.roles;
      state.user = action.payload.data.user;
      state.isHOD = action.payload.data.user.designation == 'HOD'
    },
  }
})


export function fetchUserPermissionsEpic(action$) {
  return action$.pipe(
    ofType(fetchUserPermissions.type),
    switchMap(async (action: any) => {
      const res = await axios.get(`/my-permissions`);
      return {
        data: res.data,
      }
    }),
    map(fetchedUserPermissions),
  );
}

const  {fetchedUserPermissions}  = userPermissionsSlice.actions;

export const {
  fetchUserPermissions
} = userPermissionsSlice.actions;

export default userPermissionsSlice.reducer;
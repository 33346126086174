import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ofType} from 'redux-observable';
import {switchMap, map} from 'rxjs/operators';
import axios from "utils/axios";
import _ from 'lodash';
import moment from 'moment';

interface BatchState {
  data: Array<object>,
  batches: Array<object>,
  classrooms: Array<object>,
  students: Array<object>,
  subjects: Array<object>,
  selectedBatch: any,
  selectedClassroomId: any,
  error: any,
  loading: boolean,
  batch: object,
}

const initialState: BatchState = {
  data: [],
  batches: [],
  classrooms: [],
  students: [],
  subjects: [],
  selectedBatch: null,
  selectedClassroomId: null,
  error: null,
  loading: true,
  batch: null,
};

const classroomSlice = createSlice({
  name: 'my-classroom',
  initialState: initialState,
  reducers: {
    selectBatch(state, action) {
      state.selectedBatch = action.payload.batch
    },
    fetchMyClassrooms(state, action) {
      state.loading = true;
    },
    fetchedClassrooms(state, action) {
      state.loading = false;
      state.error = null;
      state.classrooms = action.payload.data;
    },
    selectClassroom(state, action) {
      state.selectedClassroomId = action.payload.selectedClassroomId
    },
    setClassroomData(state, action) {
      state.loading = false;
      state.error = action.payload?.err
      state.subjects = action.payload.subjects;
      state.students = action.payload.students;
    }
  }
})

export function fetchMyClassroomEpic(action$) {
  return action$.pipe(
    ofType(fetchMyClassrooms.type),
    switchMap(async (action: any) => {
      console.log(action.payload)
      const res = await axios.get(`/classrooms/my-classrooms`);
      return {
        data: res.data,
      }
    }),
    map(fetchedClassrooms),
  );
}

const {
   fetchedClassrooms,
} = classroomSlice.actions

export const {
  fetchMyClassrooms,
  selectClassroom
} = classroomSlice.actions;

export default classroomSlice.reducer;
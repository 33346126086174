import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import timetableSlice, {
  fetchTimetableEpic,
  fetchClassroomTimetableEpic,
} from "./slices/timetableSlice";
import classroomSlice, {
  fetchClassroomsEpic,
  fetchClassroomDataEpic,
} from "./slices/classroomSlice";
import lessonPlanSlice, {
  fetchLessonPlanEpic,
  createClassroomSessionEpic,
  addBelowClassroomSessionsEpic,
} from "./slices/lessonPlanSlice";
import sessionsSlice, {
  fetchSessionsEpic,
  createSessionsEpic,
  addBelowSessionsEpic,
} from "./slices/sessionsSlice";
import batchSubjectsSlice, {
  fetchBatchSubjectsEpic,
} from "./slices/batchSubjectsSlice";
import userPermissionsSlice, {
  fetchUserPermissionsEpic,
} from "./slices/userPermissionsSlice";
import myClassroomSlice, {
  fetchMyClassroomEpic,
} from "./slices/myClassroomSlice";
import assessmentsSlice, {
  fetchAssessmentsEpic,
} from "./slices/assessmentsSlice";
import assessmentMarksSlice, {
  fetchAssessmentMarksEpic,
} from "./slices/assessmentMarksSlice";

export const rootEpic = combineEpics(
  fetchTimetableEpic,
  fetchLessonPlanEpic,
  fetchSessionsEpic,
  fetchBatchSubjectsEpic,
  createSessionsEpic,
  addBelowSessionsEpic,
  fetchUserPermissionsEpic,
  fetchClassroomsEpic,
  fetchClassroomDataEpic,
  fetchClassroomTimetableEpic,
  createClassroomSessionEpic,
  addBelowClassroomSessionsEpic,
  fetchMyClassroomEpic,
  fetchAssessmentsEpic,
  fetchAssessmentMarksEpic
);

export const rootReducer = combineReducers({
  timetable: timetableSlice,
  classrooms: classroomSlice,
  lessonPlan: lessonPlanSlice,
  sessions: sessionsSlice,
  batchSubjects: batchSubjectsSlice,
  userPermissions: userPermissionsSlice,
  myClassrooms: myClassroomSlice,
  assessments: assessmentsSlice,
  assessmentMarks: assessmentMarksSlice,
});

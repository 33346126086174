import 'antd/dist/antd.less';
import ErrorBoundary from 'components/ErrorBoundary';
import _ from 'lodash';
import LoginPage from 'pages/LoginPage/LoginPage';
import React, {useEffect, useState} from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import store from 'redux/store';
import {Subject} from 'rxjs';
import axios from 'utils/axios';
import './main.less';
import './App.less';
import routes from './routes';
import {
  QueryClient,
  QueryClientProvider
} from 'react-query'

const queryClient = new QueryClient();
const errorSubject = new Subject();
const errorTypes = {
  networkError: 'Network error',
  unknownError: 'Unknown error',
  sessionExpired: 'Session expired'
}

axios.interceptors.response.use(function (response) {
  errorSubject.next(null);
  return response;
}, function (error) {
  console.log(error);
  
  if (error.message == 'Network Error') {
    errorSubject.next(errorTypes.networkError);
  } else if (error.response.status == 401) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  } else {
    errorSubject.next(errorTypes.unknownError);
  }
  
  return Promise.reject(error);
});

const permissionsSubject = new Subject();
axios.get('/my-permissions')
  .then(res => {
    let permissions = _.mapKeys(res.data.permissions, function (v, k) {
      return 'can_' + _.snakeCase(k);
    })
    let user = {
      username: res.data.username,
      user: res.data.user,
      roles: res.data.roles,
    }
    
    permissionsSubject.next({permissions, user});
  })

export const PermissionsContext = React.createContext(null)
export const UserContext = React.createContext<any>(null)

const initialState = {
  permissions: {},
  user: {
    username: '',
    user: {},
    roles: []
  }
}

function App() {
  let [errorType, setErrorType] = useState(null);
  let [state, setState] = useState<any>(initialState);
  
  useEffect(() => {
    errorSubject.subscribe((errorType) => {
      setErrorType(errorType);
    })
  }, []);
  
  useEffect(() => {
    permissionsSubject.subscribe((data: any) => {
      setState({
        permissions: data.permissions,
        user: data.user
      });
    });
  }, [])
  
  if (!localStorage.getItem('token')) {
    return (
      <LoginPage/>
    )
  }
  
  return (
    <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ErrorBoundary>
        {errorType == errorTypes.networkError && <NetworkErrorView/>}
        <PermissionsContext.Provider value={state.permissions}>
          <UserContext.Provider value={state.user}>
            <div className="App">
              <BrowserRouter>
                <Switch>
                  {routes.map((route, index) => {
                    if (route.routes) {
                      return (
                        <Route key={index} exact={route.exact} path={route.path}>
                          <route.component routes={route.routes} pageTitle={route.name}/>
                        </Route>
                      );
                    } else {
                      return (
                        <Route
                          key={index}
                          exact={route.exact}
                          path={route.path}
                          component={route.component}
                          pageTitle={route.name}/>
                      );
                    }
                  })}
                  
                  <Route path="*">
                    <div>
                      Route not found
                    </div>
                  </Route>
                </Switch>
              </BrowserRouter>
            </div>
          </UserContext.Provider>
        </PermissionsContext.Provider>
      </ErrorBoundary>
    </Provider>
    </QueryClientProvider>
  );
}

function NetworkErrorView() {
  return (
    <div style={{
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      zIndex: 100,
      textAlign: 'center',
      width: '100%'
    }}>
      <div style={{textAlign: 'center'}}>
        <span style={{background: '#f9edbe', border: '1px solid #f0c36d', padding: '4px 8px',}}>
          <b>
            {'Network Error'}
          </b>
        </span>
      </div>
    </div>
  )
}

export default App;

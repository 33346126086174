import { useState, useEffect } from 'react';
import axios from 'utils/axios';

export function useMockFetch(promiseFn: Function) {
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true)
    promiseFn()
      .then(res => {
        setData(res)
        setLoading(false)
      }).catch(err => {
        setLoading(false);
        setError(null)
      })
  }, []);

  return {
    data, loading
  };
}

export default function useFetch(url: string) {
  const [state, setState] = useState({
    data: null,
    error: null,
    loading: true,
  })

  useEffect(() => {
    setState({ ...state, loading: true })
    axios.get(url)
      .then(res => {
        setState({ ...state, data: res.data, loading: false })
      }).catch(err => {
        setState({ ...state, error: err, loading: false })
      })
  }, []);

  return state;
}
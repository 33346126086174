import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ofType} from 'redux-observable';
import {switchMap, map} from 'rxjs/operators';
import axios from "utils/axios";
import _ from 'lodash';
import moment from 'moment';

interface BatchSubjectsState {
  data: any,
  error: any,
  loading: boolean,
  selectedSubject: any,
  batch: any,
  selectedBatch: any,
  subjects: any,
  semesters:any,
}

const initialState: BatchSubjectsState = {
  data: null,
  error: null,
  loading: false,
  selectedSubject: null,
  batch: null,
  selectedBatch: null,
  subjects: [],
  semesters:[],
};


const batchSubjectsSlice = createSlice({
  name: 'batchSubjects',
  initialState: initialState,
  reducers: {
    fetchBatchSubjects(state, action) {
      state.loading = true;
      state.batch = action.payload.batch
    },
    fetchedBatchSubjects(state, action) {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
      state.semesters = action.payload.data.semesters;
    },
    selectSubject(state, action) {
      state.loading = false;
      state.error = null;
      state.selectedSubject = action.payload.selectedSubject;
    },
    selectBatch(state, action) {
      state.loading = false;
      state.error = null;
      state.selectedBatch = action.payload.selectedBatch;
    }
  }
})


export function fetchBatchSubjectsEpic(action$) {
  return action$.pipe(
    ofType(fetchBatchSubjects.type),
    switchMap(async (action: any) => {
      const res = await axios.get(`/curriculums/${action.payload.batch}/curriculum-subjects`);
      return {
        data: res.data,
      }
    }),
    map(fetchedBatchSubjects),
  );
}

const {fetchedBatchSubjects} = batchSubjectsSlice.actions
export const {
  fetchBatchSubjects, selectSubject, selectBatch
} = batchSubjectsSlice.actions;

export default batchSubjectsSlice.reducer;